// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-benefits-concrete-data-mdx": () => import("./../../../content/benefits/concrete-data.mdx" /* webpackChunkName: "component---content-benefits-concrete-data-mdx" */),
  "component---content-benefits-instant-insights-mdx": () => import("./../../../content/benefits/instant-insights.mdx" /* webpackChunkName: "component---content-benefits-instant-insights-mdx" */),
  "component---content-benefits-intuitive-interface-mdx": () => import("./../../../content/benefits/intuitive-interface.mdx" /* webpackChunkName: "component---content-benefits-intuitive-interface-mdx" */),
  "component---content-benefits-polished-reports-mdx": () => import("./../../../content/benefits/polished-reports.mdx" /* webpackChunkName: "component---content-benefits-polished-reports-mdx" */),
  "component---content-benefits-total-data-ownership-mdx": () => import("./../../../content/benefits/total-data-ownership.mdx" /* webpackChunkName: "component---content-benefits-total-data-ownership-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agencies-js": () => import("./../../../src/pages/agencies.js" /* webpackChunkName: "component---src-pages-agencies-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */)
}

