module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PTNGSTB","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"benefits":"/codebuild/output/src045276070/src/variametrix-site-2021/src/templates/benefit.js","default":"/codebuild/output/src045276070/src/variametrix-site-2021/src/templates/page.js"},"extensions":[".mdx"],"gatsbyRemarkPlugins":[],"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VariaMetrix","short_name":"Varia","start_url":"/","background_color":"#ffffff","theme_color":"#ff3399","display":"standalone","icon":"static/fav-512.png","icons":[{"src":"/fav-16.png","sizes":"16x16","type":"image/png","purpose":"any"},{"src":"/fav-32.png","sizes":"32x32","type":"image/png","purpose":"any"},{"src":"/fav-48.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"/fav-96.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"/fav-144.png","sizes":"144x144","type":"image/png","purpose":"any"},{"src":"/apple-fav.svg","sizes":"16x16","type":"image/svg","purpose":"badge"},{"src":"/fav-512-mask.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"241a6c5ff8f1405acfa979288c18d046"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
